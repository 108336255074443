import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import ContactForm from "$components/ContactForm"
import Container from "$components/Container"
import Footer from "$components/Footer"
import Header from "$components/Header"
import SEO from "$components/SEO"

import backgroundOffice from "./assets/background-office.jpg"

const Background = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${backgroundOffice});
  background-size: cover;
  margin: 0;
  overflow: hidden;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: auto;

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    display: block;
  }
`

const ButtonLink = styled.button`
  background-color: ${props => props.theme.goldenDream};
  border-radius: 3px;
  border-bottom: outset;
  box-sizing: border-box;
  color: ${props => props.theme.tundora};
  display: block;
  font-size: 1.2rem;
  margin: 0 16px;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;

  :first-child {
    margin-left: 0;
  }

  :hover {
    background-color: ${props => props.theme.darken(props.theme.goldenDream)};
  }

  :last-child {
    margin-right: 0;
  }

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    margin: 16px 0;

    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }
  }
`
const CampaignContact = styled.div`
  align-self: flex-start;
  margin: 0 0 0 20px;
  flex: 1;
  position: sticky;
  top: 0;

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    margin: 0;
  }
`

const CampaignContainer = styled(Container)`
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    linear-gradient(
      to right,
      ${props => props.theme.rouge} -25%,
      ${props => props.theme.goldenDream} 125%
    );
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  margin: 40px auto;
  padding: 40px;

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.3)
      ),
      linear-gradient(
        to bottom,
        ${props => props.theme.rouge} -25%,
        ${props => props.theme.goldenDream} 125%
      );
    box-sizing: border-box;
    display: block;
    padding: 0 16px;
    width: 100%;
  }
`

const CampaignText = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 20px 0 0;

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    margin: 0 0 20px;
  }
`

const H3 = styled.h3`
  text-align: left;
`

const title = "3 Simple Security Checks | Lotus Network Solutions"

export default () => {
  return (
    <>
      <SEO noindex title={title} />
      <Header />
      <main>
        <Background>
          <h1>3 Simple Security Checks</h1>
          <CampaignContainer>
            <CampaignText>
              <h2>Secure your practice now!</h2>
              <p>
                Your practice relies on your internet connection to get things
                done, but it could be vulnerable to attacks. Make sure you check
                off these 3 simple points, essential for any practice:
              </p>
              <H3>
                1. Don't use default credentials on routers and access points
              </H3>
              <p>
                If you haven't changed the credentials on your router or access
                points, you'll be using the default username and password, set
                by the manufacturer. Anyone who looks up the model of the router
                may be able to access your data and change settings, so changing
                the credentials is a simple fix to block this attack.
              </p>
              <H3>2. Have separate business and guest wireless networks</H3>
              <p>
                It's good courtesy to any client to have WiFi available in your
                office, but have you protected your network from outsiders? An
                infected device connected to your internal network - unbeknownst
                to you or the owner - could access sensitive data or wreak
                havoc. If you offer complimentary internet connectivity to your
                clients from your office WiFi, ensure you have a guest network
                separated from your internal network.
              </p>
              <H3>3. Use a VPN to connect remotely</H3>
              <p>
                In our current remote work environments, it's more important
                than ever to offer your staff the ability to connect to the
                office from wherever they are, but how can you make sure their
                connections to your systems are secure? Requiring connections to
                your office servers via a Virtual Private Network, or VPN,
                protects the traffic between staff and your internal systems
                with sensitive data.
              </p>
            </CampaignText>
            <CampaignContact>
              <h2>Find out more</h2>
              <p>
                Unsure about any of these points? Lotus Network Solutions
                specialises in managed IT services for accounting practices. Ask
                us about Work from Home solutions, backup systems, network
                security and more - Lotus is here to help.
              </p>
              <p>
                Click the links below for more information or contact us
                directly to see how Lotus can help your business.
              </p>
              <ButtonContainer>
                <ButtonLink as={Link} to="/services/">
                  See all services &rarr;
                </ButtonLink>
                <ButtonLink as="a" href="tel:1300767428">
                  <ion-icon name="call-outline" size="1rem" /> 1300 767 428
                </ButtonLink>
              </ButtonContainer>
              <H3>Contact Us</H3>
              <ContactForm />
            </CampaignContact>
          </CampaignContainer>
        </Background>
      </main>
      <Footer />
    </>
  )
}
